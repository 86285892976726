import React, { useContext, useEffect, useState } from 'react';
import { DeleteOutline, Close as CloseIcon } from '@mui/icons-material';
import { QuestionSet } from '../../schemas';
import { Range } from 'react-date-range';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Button,
  styled,
  IconButton,
  List,
  Paper,
  Stack,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
} from '@mui/material';
import {
  DndContext,
  closestCenter,
  TouchSensor,
  MouseSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';

import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import CustomizedLocalizedQuestionText from '../CustomizedLocalizedQuestionText';
import { getLocalizedText } from '../../helpers/custom-i18n';
import { useTranslation } from 'react-i18next';
import { CalenderButtonIcon, EyeIcon, FlagIcon, theme } from '@konecorp/ui-library';
import { PickerWithButtonField } from '../DatePickerWithIcon';
import dayjs, { Dayjs } from 'dayjs';
import { DateRangePickerComponent } from '../DateRangePicker';
import Context from '../../context';
import { API_TYPE, put } from '../../helpers/fetch';
import { useGetToken } from '../../hooks/useGetToken';
import { useParams } from 'react-router-dom';

const StyledPaper = styled(Paper)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexGrow: 1,
  backgroundColor: theme.palette.grey[100],
  margin: '20px',
  padding: '16px',
}));

const QuestionText = styled(Paper)<{ isSelected: boolean }>(({ isSelected }) => ({
  display: 'flex',
  flexGrow: 1,
  backgroundColor: isSelected ? '#FFFFFF' : '#F3F6FE',
  marginRight: '20px !important',
  padding: '16px',
  whiteSpace: 'normal',
  wordBreak: 'break-word',
  border: isSelected ? '2px dashed #E2E2E2' : '2px solid transparent',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const StyledTypography = styled(Typography)(() => ({
  fontWeight: 500,
}));

const StyledButton = styled(Button)<{ disableHover?: boolean }>(({ disableHover }) => ({
  display: 'flex',
  alignItems: 'center',
  ...(disableHover && {
    pointerEvents: 'none',
    cursor: 'default',
  }),
}));

const DateBoxContainer = styled(Box)<{}>(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  border: '1px solid #000000',
  borderRadius: '8px',
  overflow: 'hidden',
  width: '60px',
  textAlign: 'center',
  backgroundColor: '#FFFFFF',
  flexShrink: 0,
}));
const DateBoxTop = styled(Box)(() => ({
  padding: '4px',
  fontSize: '12px',
  color: '#3D3D3D',
}));
const DateBoxBottom = styled(Box)<{}>(() => ({
  backgroundColor: '#3D3D3D',
  padding: '2px',
  fontSize: '14px',
  fontWeight: 'bold',
  color: '#FFFFFF',
  borderRadius: '4px',
  width: '95%',
  marginBottom: '2px',
}));
const StrikethroughText = styled('div')<{ isSelected: boolean }>(({ isSelected }) => ({
  textDecoration: isSelected ? 'line-through' : 'none',
  color: isSelected ? '#717171' : 'inherit',
}));

type QuestionListProps = {
  questionSets?: QuestionSet[];
  secondScreen: boolean;
  inspectionDate: Dayjs;
  startDate?: string | null;
  targetDate?: string | null;
  questionsList: any[];
  setQuestionsList: React.Dispatch<React.SetStateAction<any[]>>;
  handleDateChange: (date: Dayjs | null) => void;
  setNeedChange: React.Dispatch<React.SetStateAction<boolean>>;
};

// Function to generate unique IDs
const generateId = (text: string, index: number) => `${text}-${index}`;
const SortableItem = ({
  question,
  index,
  secondScreen,
  questionsList,
  selectedIndices,
  filteredQuestions,
  handleIconButtonClick,
  setQuestionsList,
}: {
  question: any;
  index: number;
  secondScreen: boolean;
  questionsList: any;
  selectedIndices: any;
  filteredQuestions: any;
  handleIconButtonClick: any;
  setQuestionsList?: any;
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: question.id,
  });
  const { i18n } = useTranslation();
  const { updateIsLoading, updateErrorMessage } = useContext(Context);
  const [openDialog, setOpenDialog] = useState(false);
  const [startDate, setStartDate] = useState<Date | undefined>(
    question.startDate ? new Date(question.startDate) : undefined
  );
  const [endDate, setEndDate] = useState<Date | undefined>(
    question.endDate ? new Date(question.endDate) : undefined
  );
  const [getTokenFunction] = useGetToken();
  const { networkNumber } = useParams();
  const [state, setState] = useState<Range[]>([
    {
      startDate: startDate || new Date(),
      endDate: endDate || new Date(),
      key: 'selection',
    },
  ]);
  const { t } = useTranslation();
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: !secondScreen ? 'grab' : '',
    outline: 'none',
    border: 'none',
    '&:focus': {
      outline: 'none',
    },
    '&:hover': {
      border: 'none',
    },
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleSaveDialog = async (questionSk: any) => {
    const token = await getTokenFunction();

    try {
      updateIsLoading(true);
      if (startDate !== undefined && endDate !== undefined) {
        const response = await put(
          `v1/installations/${networkNumber}/plan`,
          token,
          API_TYPE.APPLICATION,
          {
            sk: question.sk,
            startDate: startDate,
            endDate: endDate,
          }
        );
        if (response) {
          setQuestionsList((prevQuestions: any) =>
            prevQuestions.map((q: any) =>
              q.sk === questionSk
                ? {
                    ...q,
                    startDate: startDate,
                    endDate: endDate,
                  }
                : q
            )
          );
        }
      }
    } catch (error) {
      updateErrorMessage({ message: t('teamList.cannotSaveContact'), error });
    } finally {
      updateIsLoading(false);
    }

    setOpenDialog(false);
  };

  const formatDate = (date: Date | undefined) => {
    return date ? dayjs(date).format('DD.MM') : '';
  };

  const handleSelect = (item: any) => {
    setState([item.selection]);
    setStartDate(item.selection.startDate);
    setEndDate(item.selection.endDate);
  };

  return (
    <Stack
      ref={setNodeRef}
      key={question.id}
      direction="row"
      alignItems="center"
      spacing={2}
      sx={{ width: '100%', padding: '6px 0', pointerEvents: 'auto' }}
    >
      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        sx={{
          width: '100%',
          padding: '4px 0',
          pointerEvents: 'auto',
          display: 'flex',
          justifyContent: 'space-between', // Ensures text and calendar are side-by-side
          ...style,
        }}
        {...attributes}
        {...listeners}
      >
        <Typography
          variant="body1"
          sx={{ minWidth: 30, textAlign: 'right', color: '#0171B9' }}
        >
          {index + 1}.
        </Typography>
        <QuestionText
          isSelected={selectedIndices.includes(
            secondScreen
              ? questionsList.findIndex((q: any) => filteredQuestions?.[index] === q) ??
                  -1
              : index
          )}
        >
          <StrikethroughText
            isSelected={selectedIndices.includes(
              secondScreen
                ? questionsList.findIndex((q: any) => filteredQuestions?.[index] === q) ??
                    -1
                : index
            )}
          >
            <CustomizedLocalizedQuestionText
              localaizedQuestionText={getLocalizedText(i18n, question.text)?.text || ''}
            />
          </StrikethroughText>

          {secondScreen && (
            <Stack
              key={question.sk}
              direction="row"
              alignItems="center"
              spacing={2}
              sx={{
                width: 'auto',
                paddingTop: '10px',
              }}
            >
              {/* Show DateBoxContainer if startDate and endDate exist */}
              {startDate && endDate ? (
                <Stack key={question.sk}>
                  <IconButton
                    onClick={handleOpenDialog}
                    sx={{
                      width: '40px',
                      height: '40px',
                      borderRadius: '50%',
                      backgroundColor: 'white',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <DateBoxContainer>
                      <DateBoxTop>{formatDate(startDate)}. -</DateBoxTop>
                      <DateBoxBottom>{formatDate(endDate)}.</DateBoxBottom>
                    </DateBoxContainer>
                  </IconButton>
                </Stack>
              ) : (
                // Show CalenderButtonIcon if startDate or endDate is missing
                <IconButton
                  onClick={handleOpenDialog}
                  sx={{
                    width: '40px',
                    height: '40px',
                    borderRadius: '50%',
                    backgroundColor: 'white',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <CalenderButtonIcon style={{ fontSize: '24px', color: 'white' }} />
                </IconButton>
              )}

              {/* Dialog to show DateRangePickerComponent */}
              <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                fullWidth
                sx={{
                  '& .MuiDialog-paper': {
                    maxWidth: '400px',
                    width: '80%',
                    height: 'auto',
                    maxHeight: '80vh',
                    margin: '0 auto',
                    padding: 0,
                  },
                }}
              >
                <DialogTitle
                  sx={{
                    position: 'relative',
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                    gap: 2,
                    paddingBottom: 1,
                  }}
                >
                  <Typography
                    variant="h6"
                    color="inherit"
                    sx={{ marginRight: '10px', flexShrink: 0 }}
                  >
                    {index + 1} / {filteredQuestions.length}
                  </Typography>
                  <Box sx={{ flex: 1, wordWrap: 'break-word', minWidth: 0 }}>
                    <CustomizedLocalizedQuestionText
                      localaizedQuestionText={
                        getLocalizedText(i18n, question.text)?.text || ''
                      }
                    />
                  </Box>
                  <IconButton
                    edge="end"
                    color="inherit"
                    onClick={handleCloseDialog}
                    sx={{
                      position: 'absolute',
                      right: 8,
                      top: 8,
                      flexShrink: 0,
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </DialogTitle>

                <DialogContent
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignContent: 'center',
                    overflowY: 'auto',
                    padding: '0px',
                    maxHeight: '80vh',
                    alignItems: 'stretch',
                  }}
                >
                  <DateRangePickerComponent state={state} handleSelect={handleSelect} />
                </DialogContent>

                <DialogActions
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    padding: '20px',
                  }}
                >
                  <Button
                    color="primary"
                    sx={{
                      backgroundColor: '#033dfc',
                      color: '#fff',
                      borderRadius: '20px',
                      padding: '8px 24px',
                      width: '100%',
                      '&:hover': {
                        backgroundColor: '#1565c0',
                      },
                      textTransform: 'none',
                    }}
                    onClick={handleCloseDialog}
                  >
                    {t('installationPlanList.cancel')}
                  </Button>
                  <Button
                    sx={{
                      backgroundColor: '#033dfc',
                      color: '#fff',
                      borderRadius: '20px',
                      width: '100%',
                      padding: '8px 24px',
                      '&:hover': {
                        backgroundColor: '#1565c0',
                      },
                      textTransform: 'none',
                    }}
                    onClick={() => handleSaveDialog(question.sk)}
                    color="primary"
                  >
                    {t('installationPlanList.save')}
                  </Button>
                </DialogActions>
              </Dialog>
            </Stack>
          )}
        </QuestionText>
      </Stack>

      {/* Display delete icon only if not secondScreen */}
      {!secondScreen && (
        <IconButton
          color="primary"
          aria-label="delete"
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            handleIconButtonClick(index);
          }}
        >
          {selectedIndices.includes(index) ? <DeleteIcon /> : <DeleteOutline />}
        </IconButton>
      )}
    </Stack>
  );
};

const InstallationPlanList = ({
  questionsList,
  setQuestionsList,
  questionSets,
  secondScreen,
  inspectionDate,
  startDate,
  targetDate,
  handleDateChange,
  setNeedChange,
}: QuestionListProps): JSX.Element => {
  const { t } = useTranslation();
  const [selectedIndices, setSelectedIndices] = useState<number[]>([]);

  const questionSetToShow = questionSets?.find((q) => q.questionSetId === 'PLAN');
  const touchSensor = useSensor(TouchSensor, {
    // Adjust activation conditions for touch events, if needed
    activationConstraint: {
      distance: 10, // Minimum distance in pixels before dragging starts
    },
  });

  const mouseSensor = useSensor(MouseSensor);

  const sensors = useSensors(mouseSensor, touchSensor);
  useEffect(() => {
    if (questionSetToShow?.questions) {
      setQuestionsList(
        questionSetToShow.questions.map((q, index) => ({
          ...q,
          id: generateId(q.text[0].text, index), // Assign unique ID
        }))
      );
    }
  }, [questionSetToShow]);

  useEffect(() => {
    // Recalculate selectedIndices based on the `deleted` property of the questions
    const updatedSelectedIndices =
      questionsList
        .map((question, index) => (question.deleted ? index : -1))
        .filter((index) => index !== -1) || [];
    setSelectedIndices(updatedSelectedIndices);
  }, [questionsList]);

  const filteredQuestions = secondScreen
    ? questionsList.filter((question) => !question.deleted)
    : questionsList;

  const handleIconButtonClick = (index: number) => {
    const actualIndex = secondScreen
      ? questionsList.findIndex((question) => filteredQuestions?.[index] === question)
      : index;

    if (actualIndex === undefined || actualIndex === -1) return;

    const isCurrentlySelected = selectedIndices.includes(actualIndex);

    // Update the `deleted` property in the questionSets object
    const updatedQuestionsList = questionsList.map((question, idx) =>
      idx === actualIndex ? { ...question, deleted: !isCurrentlySelected } : question
    );

    setQuestionsList(updatedQuestionsList);
    setNeedChange(true);
  };

  // Handle drag end event
  const handleDragEnd = (event: any) => {
    const { active, over } = event;
    if (active.id !== over?.id) {
      const oldIndex = questionsList.findIndex((q) => q.id === active.id);
      const newIndex = questionsList.findIndex((q) => q.id === over?.id);
      setQuestionsList(arrayMove(questionsList, oldIndex, newIndex));
      setNeedChange(true);
    }
  };

  return (
    <List sx={{ width: '100%', margin: 'auto', padding: 0 }}>
      {secondScreen && (
        <StyledPaper>
          <StyledTypography variant="body1">
            {t('installationPlanList.startInstallation')}
          </StyledTypography>

          <StyledButton variant="outlined" color="inherit" disableHover={true}>
            <FlagIcon sx={{ fontSize: 20, marginRight: 1 }} />
            <StyledTypography variant="body1">{startDate}</StyledTypography>
          </StyledButton>
        </StyledPaper>
      )}
      {!secondScreen ? (
        <DndContext
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
          sensors={sensors}
        >
          <SortableContext
            items={filteredQuestions.map((q) => q.id)}
            strategy={verticalListSortingStrategy}
          >
            <List sx={{ width: '100%', margin: 'auto', padding: 0 }}>
              {filteredQuestions.map((question, index) => (
                <SortableItem
                  key={question.id}
                  question={question}
                  index={index}
                  secondScreen={secondScreen}
                  questionsList={questionsList}
                  selectedIndices={selectedIndices}
                  filteredQuestions={filteredQuestions}
                  handleIconButtonClick={handleIconButtonClick}
                />
              ))}
            </List>
          </SortableContext>
        </DndContext>
      ) : (
        <List sx={{ width: '100%', margin: 'auto', padding: 0 }}>
          {filteredQuestions.map((question, index) => (
            <SortableItem
              key={question.id}
              question={question}
              index={index}
              secondScreen={secondScreen}
              setQuestionsList={setQuestionsList}
              questionsList={questionsList}
              selectedIndices={selectedIndices}
              filteredQuestions={filteredQuestions}
              handleIconButtonClick={handleIconButtonClick}
            />
          ))}
        </List>
      )}

      {secondScreen && (
        <>
          <StyledPaper>
            <StyledTypography variant="body1">
              {t('installationPlanList.endInstallation')}
            </StyledTypography>

            <StyledButton variant="outlined" color="inherit" disableHover={true}>
              <FlagIcon sx={{ fontSize: 20, marginRight: 1 }} />
              <StyledTypography variant="body1">{targetDate}</StyledTypography>
            </StyledButton>
          </StyledPaper>
          <StyledPaper>
            <StyledTypography variant="body1">
              {t('installationPlanList.inspectionDate')}
            </StyledTypography>
            <StyledButton variant="outlined" color="inherit">
              {inspectionDate.isValid() ? (
                <>
                  <EyeIcon sx={{ fontSize: 20, marginRight: 1 }} />
                  <PickerWithButtonField
                    inspectDate={inspectionDate}
                    handleDateChange={handleDateChange}
                    calendarIcon={false}
                  />
                </>
              ) : (
                <PickerWithButtonField
                  inspectDate={inspectionDate}
                  handleDateChange={handleDateChange}
                  calendarIcon={true}
                />
              )}
            </StyledButton>
          </StyledPaper>
        </>
      )}
    </List>
  );
};

export default InstallationPlanList;
